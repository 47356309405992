$(document).ready(function () {

    $(this).scroll(function () {

        $('.navbar').toggleClass('scrolled', $(this).scrollTop() > $('.navbar').height());
    });

    $('.slide-one').owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        autoplay: true,
        dots: false,
        autoplayTimeout: 2500,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    });

    $('.slide-two').owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        autoplay: true,
        autoplayTimeout: 3000,
        smartSpeed: 1500,
        dots: false,
        responsive: {
            0: {
                items: 1
            }

        }
    });

});